// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dqVxVNPDmSuBRfTLUeu3 {\n    display: grid;\n    grid-auto-flow: column;\n    justify-self: center;\n\n    background-color: var(--background-secondary-color);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius);\n\n    overflow: hidden;\n}\n\n.dqVxVNPDmSuBRfTLUeu3 > div:not(:last-child) {\n    border-right: 0.1rem solid var(--border-default-color);\n}\n\n.MsmhN4kZy8fFe0ki238W {\n    display: grid;\n    align-items: center;\n\n    color: var(--text-primary-color);\n    font-weight: 600;\n\n    padding: 0 2rem;\n    height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./base/business/rate/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;;IAEpB,mDAAmD;;IAEnD,gDAAgD;IAChD,2CAA2C;;IAE3C,gBAAgB;AACpB;;AAEA;IACI,sDAAsD;AAC1D;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,gCAAgC;IAChC,gBAAgB;;IAEhB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".container {\n    display: grid;\n    grid-auto-flow: column;\n    justify-self: center;\n\n    background-color: var(--background-secondary-color);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius);\n\n    overflow: hidden;\n}\n\n.container > div:not(:last-child) {\n    border-right: 0.1rem solid var(--border-default-color);\n}\n\n.title {\n    display: grid;\n    align-items: center;\n\n    color: var(--text-primary-color);\n    font-weight: 600;\n\n    padding: 0 2rem;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "dqVxVNPDmSuBRfTLUeu3",
	"title": "MsmhN4kZy8fFe0ki238W"
};
export default ___CSS_LOADER_EXPORT___;
