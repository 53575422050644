
import actions from "@/base/store/actions.js";

import monitorCommands from "@/app/monitors/commands.js";


const MONITOR_COMMANDS = monitorCommands.getMonitorCommands();

export function getStudentCallbacks(store) {
    return {
        setUserBadEmail() {
            store.dispatch(actions.user.setIsBadEmail(true));
        },

        /* --- */

        onUpdate(data) {
            if (data.cmd === MONITOR_COMMANDS.USER_EMAIL_BAD_DETECTED) {
                this.setUserBadEmail();
            } else {
                console.log(`[SSE]: Unknown command ${data}`); // eslint-disable-line no-console
            }
        },

        onUpdateAll() {},
    };
}

export default {
    getStudentCallbacks,
};
