import User from "@/base/project/user.js";

import visibility from "@/base/lib/visibility.js";
import random from "@/base/lib/random.js";

import MonitorRestarter from "@/base/monitors/restarter.js";
import EventSourceWrapper from "@/base/monitors/events.js";

import actions from "@/base/store/actions.js";

import callbacksByRole from "./callbacks/index.js";


const getCallbacks = (store) => {
    return {
        onStart() {
            store.dispatch(actions.monitors.setSocketMonitorOn());
        },
        onStop() {
            store.dispatch(actions.monitors.setSocketMonitorOff());
        },
        onUpdate(data) {
            const callbacks = callbacksByRole.getCallbacks(store);

            if (callbacks.onUpdate) {
                callbacks.onUpdate(data);
            }
        },
        onUpdateAll() {
            const callbacks = callbacksByRole.getCallbacks(store);

            if (callbacks.onUpdateAll) {
                callbacks.onUpdateAll();
            }
        },
    };
};

class EventsMonitor {
    constructor(eventsURL, store) {
        this.id = random.randomHEX();

        this.eventsURL = eventsURL;
        this.store = store;
        this.session = "";

        this.state = {
            isAuthorized: false,
            isVisible: true,
        };

        this.isRunning = false;

        this.callbacks = getCallbacks(store);

        this.eventSource = new EventSourceWrapper(this.callbacks);
        this.monitor = new MonitorRestarter(this.eventSource);

        this.startStoreMonitor();
        this.startVisibilityMonitor();
    }

    /* --- */

    start() {
        if (this.isRunning) {
            return;
        }

        console.log("[EventsMonitor]: start monitor"); // eslint-disable-line no-console

        this.isRunning = true;

        const session = this.store.getState().user.session || "";
        const url = `${this.eventsURL}?app_id=${this.id}&session_id=${session}`;

        this.eventSource.setURL(url);
        this.monitor.start();
    }

    stop() {
        if (!this.isRunning) {
            return;
        }

        console.log("[EventsMonitor]: stop monitor"); // eslint-disable-line no-console

        this.isRunning = false;

        this.monitor.stop();
    }

    changeState() {
        if (!this.state.isVisible) {
            this.stop();
            return;
        }

        if (this.state.isAuthorized) {
            this.start();
        } else {
            this.stop();
        }
    }

    /* --- */

    setAuthorized(isAuthorized) {
        this.state.isAuthorized = isAuthorized;

        this.changeState();
    }

    setVisibility(isVisible) {
        this.state.isVisible = isVisible;

        this.changeState();
    }

    /* --- */

    startStoreMonitor() {
        this.store.subscribe(() => {
            const state = this.store.getState();
            this.storeChanged(state);
        });
    }

    startVisibilityMonitor() {
        const listener = visibility.getVisibilityEventHandler((isVisible) => {
            this.setVisibility(isVisible);
        });

        if (document) {
            document.addEventListener("visibilitychange", listener);
        }
    }

    storeChanged(state) {
        const {
            session,
            isUserLoaded,
            user,
        } = state.user;

        if (!session || !isUserLoaded) {
            this.setAuthorized(false);
            return;
        }

        const isValidRole = User.hasRoleTeacher(user)
            || User.hasRoleDistrictAdmin(user)
            || User.hasRoleStudent(user);

        this.setAuthorized(isValidRole);
    }
}

export default EventsMonitor;
