const loadWord = (services, params) => {
    const { api, actions } = services;
    const { wordId } = params;

    return async (dispatch, getState) => {
        if (!wordId) {
            return;
        }

        const state = getState();

        if (state.vocabulary.wordsById?.[wordId]?.isLoaded) {
            return;
        }

        const res = await api.vocabulary.getWord({
            session: state.user.session,
            wordId,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.vocabulary.setVocabularyWord({
            word: res.word,
            definitions: res.definitions,
        }));
    };
};

const openPopup = (services, params) => {
    return async (dispatch, getState) => {
        if (!params.word) {
            return;
        }

        const offsetY = params.offsetY || 0;
        const word = params.word || "";

        const state = getState();

        const wordAlreadyLoaded = state.vocabulary.wordsByName[word];

        if (wordAlreadyLoaded && !wordAlreadyLoaded.error) {
            dispatch(services.actions.vocabulary.openAndSetWordPopup({
                word,
                offsetY,
            }));
            return;
        }

        dispatch(services.actions.vocabulary.openWordPopup({
            word,
            offsetY,
        }));

        const res = await services.api.vocabulary.getWordByText({
            session: params.session,
            word,
        });

        if (!res.ok) {
            dispatch(services.actions.vocabulary.setVocabularyWordError({
                word,
                error: "Cannot load word",
            }));
            return;
        }

        const wordValue = res.word.word || "";

        dispatch(services.actions.vocabulary.clearVocabularyWordByName({
            word,
        }));

        dispatch(services.actions.vocabulary.setVocabularyWord({
            word: res.word,
            definitions: res.definitions,
        }));

        dispatch(services.actions.vocabulary.setWordPopupAliasWord({
            wordAlias: word,
            word: wordValue,
        }));

        if (services?.events?.onLoadWord) {
            services?.events?.onLoadWord(res.word.id);
        }
    };
};

const closePopup = (services) => {
    return (dispatch) => {
        dispatch(services.actions.vocabulary.closeWordPopup());
    };
};

export default {
    loadWord,
    openPopup,
    closePopup,
};
