// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qJ8B7qWeAFFTJEtgr9i6 {\n    overflow: auto;\n}\n\n/* --- */\n\n.PRSCTocTh5LkfblFKBpT {\n    padding: 2rem;\n\n    position: absolute;\n    top: 50%;\n    left: 50%;\n\n    transform: translate(-50%, -50%);\n\n    outline: none;\n}\n\n.OjWnEkwbfOkR54M8A5DC {\n    transform: translate(-50%, 0);\n}\n\n/* --- */\n\n.HUoCvUK9ntCNFgVuQjpc {\n    position: relative;\n}\n\n.gNCbYQuuAd1BkDaOasgg {\n    overflow-y: auto;\n    max-height: 80vh;\n}\n\n.FTCPPBtRchgXHxdJ8mpT {\n    padding: 2rem 4rem;\n\n    background-color: var(--text-primary-bg-color);\n\n    border-radius: var(--border-default-radius-2);\n}\n\n/* --- */\n\n.UkXdXCR4alBQisGnyZfW {\n    position: absolute;\n    top: 1.8rem;\n    right: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-word-card/styles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA,QAAQ;;AAER;IACI,aAAa;;IAEb,kBAAkB;IAClB,QAAQ;IACR,SAAS;;IAET,gCAAgC;;IAEhC,aAAa;AACjB;;AAEA;IACI,6BAA6B;AACjC;;AAEA,QAAQ;;AAER;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;;IAElB,8CAA8C;;IAE9C,6CAA6C;AACjD;;AAEA,QAAQ;;AAER;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf","sourcesContent":[".popupCommon {\n    overflow: auto;\n}\n\n/* --- */\n\n.popup {\n    padding: 2rem;\n\n    position: absolute;\n    top: 50%;\n    left: 50%;\n\n    transform: translate(-50%, -50%);\n\n    outline: none;\n}\n\n.popupWithOffset {\n    transform: translate(-50%, 0);\n}\n\n/* --- */\n\n.popupContent {\n    position: relative;\n}\n\n.wordCardScroller {\n    overflow-y: auto;\n    max-height: 80vh;\n}\n\n.contentLoader {\n    padding: 2rem 4rem;\n\n    background-color: var(--text-primary-bg-color);\n\n    border-radius: var(--border-default-radius-2);\n}\n\n/* --- */\n\n.close {\n    position: absolute;\n    top: 1.8rem;\n    right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupCommon": "qJ8B7qWeAFFTJEtgr9i6",
	"popup": "PRSCTocTh5LkfblFKBpT",
	"popupWithOffset": "OjWnEkwbfOkR54M8A5DC",
	"popupContent": "HUoCvUK9ntCNFgVuQjpc",
	"wordCardScroller": "gNCbYQuuAd1BkDaOasgg",
	"contentLoader": "FTCPPBtRchgXHxdJ8mpT",
	"close": "UkXdXCR4alBQisGnyZfW"
};
export default ___CSS_LOADER_EXPORT___;
