import React from "react";
import tinymce from "tinymce";

import styles from "./styles.module.css";


const TEXT_EDITOR_THEMES = {
    LIGHT: "light",
    DARK: "dark",
};

class TextEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.defaultValue || "",
            error: "",
        };

        this.fieldRef = null;

        this.onChange = this.onChange.bind(this);
        this.onFieldRef = this.onFieldRef.bind(this);
    }

    componentWillUnmount() {
        if (this.fieldRef) {
            tinymce.remove(this.fieldRef);
        }
    }

    getEditorThemeOptions() {
        if (this.props.theme === TEXT_EDITOR_THEMES.DARK) {
            return {
                skin: "oxide-dark",
                content_css: "dark",
            };
        }

        return {
            skin: "oxide",
            content_css: "default",
        };
    }

    tryInitEditor() {
        const contentStyles = [
            "body { font-family: Helvetica, sans-serif; font-size: 16px; line-height: 20px; }",
            "p { padding: 0; margin: 0; }",
        ].join(" ");

        const themeOptions = this.getEditorThemeOptions(0);

        const options = {
            license_key: "gpl",
            target: this.fieldRef,
            height: "300",
            base_url: "/static/lib/tinymce",
            plugins: "lists wordcount link",
            menubar: false,
            toolbar: [
                "undo redo",
                "bold italic underline",
                "bullist",
                "alignleft aligncenter alignright",
                "link",
            ].join(" | "),
            toolbar_mode: "floating",
            default_link_target: "_blank",
            link_default_protocol: "https://",
            link_assume_external_targets: "https://",
            branding: false,
            remove_linebreaks: false,
            apply_source_formatting: false,
            tadv_noautop: true,
            wpautop: false,
            indent: true,
            paste_auto_cleanup_on_paste: true,
            paste_remove_styles: true,
            paste_remove_styles_if_webkit: true,
            paste_strip_class_attributes: true,
            browser_spellcheck: true,
            contextmenu: false,
            content_style: contentStyles,
            fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px",

            theme: "silver",
            ...themeOptions,
        };

        tinymce.init({
            ...options,
            init_instance_callback: (editor) => {
                editor.setContent(this.state.value);

                let wordcount = editor.plugins.wordcount.body.getCharacterCount();
                let value = editor.getContent();

                this.onChange(value, wordcount);

                editor.on("KeyUp", () => {
                    wordcount = editor.plugins.wordcount.body.getCharacterCount();
                    value = editor.getContent();
                    this.onChange(value, wordcount);
                });

                editor.on("Change", (evt) => {
                    wordcount = editor.plugins.wordcount.body.getCharacterCount();
                    value = evt.level.content;
                    this.onChange(value, wordcount);
                });
            },
        });
    }

    onChange(value, wordcount) {
        const { onChange } = this.props;

        if (onChange) {
            this.setState({ value }, () => {
                onChange(value, wordcount);
            });
        } else {
            this.setState({ value });
        }

        this.validate(value);
    }

    onFieldRef(ref) {
        if (!ref) {
            return;
        }

        this.fieldRef = ref;

        try {
            this.tryInitEditor();
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    }

    getValue() {
        return this.state.value;
    }

    setValue(value) { // eslint-disable-line react/no-unused-class-component-methods
        this.setState({ value });
        this.validate(value);
    }

    setError(error) {
        this.setState({ error });
    }

    clearValue() { // eslint-disable-line react/no-unused-class-component-methods
        this.setState({
            value: this.props.defaultValue,
            error: "",
        });
    }

    validate(value) {
        const { validators } = this.props;

        for (let i = 0; i < validators.length; i += 1) {
            const error = validators[i](value);

            if (error) {
                this.setError(error);
                return false;
            }
        }

        this.setState({ error: "" });
        return true;
    }

    isValid() { // eslint-disable-line react/no-unused-class-component-methods
        return this.validate(this.getValue());
    }

    renderError() {
        const { error } = this.state;

        if (!error) {
            return null;
        }

        return (
            <span className={styles.error}>
                {error}
            </span>
        );
    }

    render() {
        const classes = [styles.formField];

        if (this.state.error) {
            classes.push(styles.formFieldError);
        }

        const { name, label } = this.props;

        const rnd = Math.floor(Math.random() * 1000);
        const rndId = `${name}-${rnd}`;

        const textarea = (
            <textarea
                ref={this.onFieldRef}
                id={rndId}
                name={rndId}
            />
        );

        return (
            <div className={classes.join(" ")}>
                <label htmlFor={rndId}>{label}</label>
                {textarea}
                {this.renderError()}
            </div>
        );
    }
}

TextEditor.defaultProps = {
    defaultValue: "",
    validators: [],
    theme: TEXT_EDITOR_THEMES.LIGHT,
    onChange: null,
};

export {
    TEXT_EDITOR_THEMES,
};

export default TextEditor;
