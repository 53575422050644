import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isLanguagesLoaded: false,
    languages: [],

    storiesById: {},

    ui: {
        stories: {},
    },
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TRANSLATION_LANGUAGES: {
            const { languages } = action.payload;

            return {
                ...state,
                isLanguagesLoaded: true,
                languages: languages || [],
            };
        }

        /* --- */

        case types.SET_TRANSLATION_STORY_LOADING_BY_ID: {
            const { storyId, language } = action.payload;

            const story = state.storiesById?.[storyId] || {};

            return {
                ...state,
                storiesById: {
                    ...state.storiesById,
                    [storyId]: {
                        ...story,
                        [language]: {
                            isLoading: true,
                            translation: null,
                            error: "",
                        },
                    },
                },
            };
        }

        case types.SET_TRANSLATION_STORY_ERROR_BY_ID: {
            const { storyId, language, error } = action.payload;

            const story = state.storiesById?.[storyId] || {};

            return {
                ...state,
                storiesById: {
                    ...state.storiesById,
                    [storyId]: {
                        ...story,
                        [language]: {
                            isLoading: false,
                            translation: null,
                            error,
                        },
                    },
                },
            };
        }

        case types.SET_TRANSLATION_STORY_BY_ID: {
            const { translation, language } = action.payload;

            const storyId = translation.id;
            const story = state.storiesById?.[storyId] || {};

            return {
                ...state,
                storiesById: {
                    ...state.storiesById,
                    [storyId]: {
                        ...story,
                        [language]: {
                            isLoading: false,
                            translation,
                            error: "",
                        },
                    },
                },
            };
        }

        /* --- */

        case types.UI_CHANGE_STORY_TRANSLATION: {
            const { storyId, languageCode, isChecked } = action.payload;

            return {
                ...state,
                ui: {
                    ...state.ui,
                    stories: {
                        ...state.ui.stories,
                        [storyId]: {
                            languageCode,
                            isChecked,
                        },
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return {
                ...initialState,
                isLanguagesLoaded: state.isLanguagesLoaded,
                languages: state.languages,
            };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
