
const MONITOR_COMMANDS = {
    DJ_IN_PROGRESS: "daily_juice_in_progress",
    DJ_COMPLETED: "daily_juice_completed",
    CLASS_STUDENTS_UPDATED: "class_students_updated",
    QUIZ_ANSWERED: "quiz_answered",
    ASSIGNMENT_ANSWERED: "assignment_answered",
    STUDENT_UPDATE_ACHIEVEMENT: "student_update_achievement",
    USER_EMAIL_BAD_DETECTED: "user_email_bad_detected",
};

const getMonitorCommands = () => {
    return { ...MONITOR_COMMANDS };
};

export default {
    getMonitorCommands,
};
