import User from "@/base/project/user.js";


const PAGES_SLUGS = {
    infoRadarChart: "info-radar-chart",
    resourcesTeacher: "resources-teacher",
    resourcesParent: "resources-parent",
    resourcesDistrictAdmin: "resources-district-admin",
};

/* --- */

const getPagesSlugs = () => {
    return {
        ...PAGES_SLUGS,
    };
};

const getResourcesSlugByRole = (user) => {
    if (User.hasRoleParent(user)) {
        return PAGES_SLUGS.resourcesParent;
    }

    if (User.hasRoleDistrictAdmin(user)) {
        return PAGES_SLUGS.resourcesDistrictAdmin;
    }

    return PAGES_SLUGS.resourcesTeacher;
};

/* --- */

export default {
    getPagesSlugs,
    getResourcesSlugByRole,
};
