import React from "react";

import classNames from "@/base/lib/class-names.js";

import Close from "@/base/components/close/index.js";
import PopupCommon from "@/base/components/popup-common/index.js";
import WordCard from "@/base/components/word-card/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";

import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";
import styles from "./styles.module.css";


const MIN_OFFSET_Y = 40;

const PopupWordCard = (props) => {
    const renderPopupContent = () => {
        if (props.isLoading) {
            return (
                <div className={styles.contentLoader}>
                    <RequestLoader />
                </div>
            );
        }

        const { word } = props;

        if (!word) {
            return null;
        }

        const className = classNames({
            [scrollbarStyles.scrollbar]: true,
            [styles.wordCardScroller]: true,
        });

        return (
            <WordCard
                className={className}
                word={word.word}
                definitions={word.definitions}
                audio={props.audio}
                onLoad={props.onLoad}
                onPlay={props.onPlay}
                onStop={props.onStop}
                onStopAll={props.onStopAll}
                isTitleVisible
            />
        );
    };

    const renderPopup = () => {
        const popupStyle = {};

        if (props.offsetY) {
            let top = MIN_OFFSET_Y;

            const topOffset = props.offsetY - MIN_OFFSET_Y;

            if (topOffset > 0) {
                top = topOffset;
            }

            popupStyle.top = `${top}px`;
        }

        const popupClassName = classNames({
            [styles.popup]: true,
            [styles.popupWithOffset]: !!props.offsetY,
        });

        return (
            <div
                className={popupClassName}
                style={popupStyle}
            >
                <div className={styles.popupContent}>
                    <Close
                        onClose={props.onClose}
                        className={styles.close}
                    />
                    {renderPopupContent()}
                </div>
            </div>
        );
    };

    /* --- */

    return (
        <PopupCommon
            className={styles.popupCommon}
            onClose={props.onClose}
            hideBodyScrollOnOpen={props.hideScrollbar}
        >
            {renderPopup()}
        </PopupCommon>
    );
};

PopupWordCard.defaultProps = {
    word: null,
    audio: {},
    offsetY: 0,
    onLoad: () => { },
    onPlay: () => { },
    onStop: () => { },
    onStopAll: () => { },
    onClose: () => { },
    hideScrollbar: true,
    isLoading: false,
};

export default PopupWordCard;
