import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TabsV2 from "@/base/components/tabs-v2/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import SectionSponsor from "@/base/components/section-sponsor/index.js";
import TabsScrolling from "@/base/components/tabs-scrolling/index.js";
import MessageErrorWithTryAgain from "@/base/components/message-error-with-try-again/index.js";
import Message from "@/base/components/message/index.js";

import SectionAnnouncement from "@/base/business/section-announcement/index.js";

import app from "@/app/app.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    classInfoById: state.studentClasses.classInfoById,
    classes: state.studentClasses.classes,

    sponsors: state.info.sponsors,
    isSponsorsLoaded: state.info.isSponsorsLoaded,
});

const StudentAnnouncements = () => {
    const [selectedClassId, setSelectedClassId] = useState(null);

    const store = useSelector(storeSelector);

    const classes = {
        data: store.classes.data || [],
        error: store.classes.error || "",
        isLoading: store.classes.isLoading
            || !store.classes.isLoaded,
    };

    /* --- */

    const loadStudentClasses = async () => {
        const res = await app.actions.student.classes.loadClasses();
        return res;
    };

    const loadClassInfo = (classId) => {
        app.actions.student.classes.loadClassInfo({ classId });
    };

    /* --- */

    const onSelectClass = (id) => {
        setSelectedClassId(id);
        loadClassInfo(id);
    };

    /* --- */

    const loadStudentClassesAndSelectClass = async () => {
        const { data } = await loadStudentClasses();

        const id = data?.[0]?.id || null;

        if (id) {
            onSelectClass(id);
        }
    };

    /* --- */

    useEffect(() => {
        loadStudentClassesAndSelectClass();
    }, []);

    /* --- */

    const getAnnouncement = (id) => {
        if (!id) {
            return {};
        }

        return store.classInfoById[id] || {};
    };

    /* --- */

    const renderSponsor = () => {
        return (
            <SectionSponsor
                imgSrc={store.sponsors.djHomeImage || ""}
                link={store.sponsors.djHomeUrl || ""}
            />
        );
    };

    const renderAnnouncement = () => {
        if (!selectedClassId) {
            return (
                <Message>
                    Please select class
                </Message>
            );
        }

        const announcement = getAnnouncement(selectedClassId);

        const content = announcement.data?.announcementContent || "";
        const title = announcement.data?.announcementTitle || "Class announcement";
        const error = announcement.error || "";

        return (
            <SectionAnnouncement
                content={content}
                title={title}
                error={error}
                onReload={() => {
                    loadClassInfo(selectedClassId);
                }}
                isLoading={announcement.isLoading || !announcement.isLoaded}
            />
        );
    };

    const renderClasses = () => {
        const tabs = classes.data.map((cl) => {
            const { id, name } = cl;

            return {
                value: id,
                label: name,
            };
        });

        if (tabs.length > 3) {
            return (
                <div className={styles.tabsScrollable}>
                    <TabsScrolling
                        tabs={tabs}
                        selected={selectedClassId}
                        onChange={onSelectClass}
                    />
                </div>
            );
        }

        return (
            <div className={styles.tabs}>
                <TabsV2
                    tabs={tabs}
                    selectedTab={selectedClassId}
                    onChange={onSelectClass}
                />
            </div>
        );
    };

    const renderContent = () => {
        if (!store.isSponsorsLoaded) {
            return (
                <RequestLoader />
            );
        }

        return (
            <>
                {renderSponsor()}
                {renderAnnouncement()}
            </>
        );
    };

    const renderClassesError = () => {
        return (
            <div className={styles.containerContent}>
                <MessageErrorWithTryAgain
                    onTryAgain={loadStudentClassesAndSelectClass}
                >
                    {classes.error}
                </MessageErrorWithTryAgain>
            </div>
        );
    };

    const renderNoClassesMessage = () => {
        return (
            <div className={styles.containerContent}>
                <Message>
                    No classes found.
                </Message>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <div className={styles.containerContent}>
                <RequestLoader />
            </div>
        );
    };

    /* --- */

    if (classes.isLoading) {
        return renderLoader();
    }

    if (classes.error) {
        return renderClassesError();
    }

    if (!classes.data.length) {
        return renderNoClassesMessage();
    }

    return (
        <div className={styles.container}>
            {renderClasses()}
            <div className={styles.content}>
                {renderContent()}
            </div>
        </div>
    );
};

StudentAnnouncements.defaultProps = {};

export default StudentAnnouncements;
