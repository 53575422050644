import array from "@/base/lib/array.js";


const getClassesDefaultGrade = (classes) => {
    for (let i = 0; i < classes.length; i += 1) {
        const cl = classes[i];

        if (cl.isPrimaryTeacher) {
            return cl.grade;
        }
    }

    if (classes.length > 0) {
        return classes[0].grade;
    }

    return "";
};

const setScoresUpdated = (oldScores, newScores) => {
    const result = [];

    const oldIds = [];

    for (let i = 0; i < oldScores.length; i += 1) {
        const score = oldScores[i];

        oldIds.push(score.id);

        let isUpdated = false;
        let { totalCorrect, totalAnswered } = score;

        const newScore = array.findOneById(newScores, score.id);

        if (newScore
            && (score.totalCorrect !== newScore.totalCorrect
                || score.totalAnswered !== newScore.totalAnswered)) {
            isUpdated = true;
            totalCorrect = newScore.totalCorrect;
            totalAnswered = newScore.totalAnswered;
        }

        result.push({
            ...score,
            totalCorrect,
            totalAnswered,
            isUpdated,
        });
    }

    for (let i = 0; i < newScores.length; i += 1) {
        const score = newScores[i];

        if (oldIds.indexOf(score.id) === -1) {
            result.push({
                ...score,
                isUpdated: true,
            });
        }
    }

    return result;
};

const unsetScoresUpdated = (oldScores, newScores) => {
    return oldScores.map((score) => {
        const newScore = array.findOneById(newScores, score.id);

        const isUpdated = newScore ? false : score.isUpdated;

        return {
            ...score,
            isUpdated,
        };
    });
};

const updateStudentsScores = (oldStudents, newStudents) => {
    const result = [];

    const newIds = (newStudents || []).map((student) => student.id);
    const oldIds = [];

    for (let i = 0; i < oldStudents.length; i += 1) {
        const student = oldStudents[i];

        if (newIds.indexOf(student.id) !== -1) {
            oldIds.push(student.id);

            const scores = student.quizzesScores || [];

            const newStudent = array.findOneById(newStudents, student.id);
            const newStudentScores = newStudent?.quizzesScores || [];

            const newScores = setScoresUpdated(scores, newStudentScores);

            result.push({
                ...newStudent,
                quizzesScores: newScores,
            });
        }
    }

    for (let i = 0; i < newStudents.length; i += 1) {
        const student = newStudents[i];

        if (oldIds.indexOf(student.id) === -1) {
            result.push({
                ...student,
                isUpdated: true,
            });
        }
    }

    return result;
};

const clearStudentsScoresUpdatedStatus = (oldStudents, newStudents) => {
    return oldStudents.map((student) => {
        const scores = student.quizzesScores || [];

        const newStudent = array.findOneById(newStudents, student.id);
        const newStudentScores = newStudent?.quizzesScores || [];

        const newScores = unsetScoresUpdated(scores, newStudentScores);

        return {
            ...student,
            isUpdated: false,
            quizzesScores: newScores,
        };
    });
};

/* --- */

const setAssignmentsScoresUpdated = (oldScores, newScores) => {
    const result = [];

    const oldIds = [];

    for (let i = 0; i < oldScores.length; i += 1) {
        const score = oldScores[i];

        oldIds.push(score.id);

        let isUpdated = false;

        const newScore = array.findOneById(newScores, score.id);

        if (newScore && newScore.status !== score.status) {
            isUpdated = true;
        }

        result.push({
            ...score,
            ...newScore,
            isUpdated,
        });
    }

    for (let i = 0; i < newScores.length; i += 1) {
        const score = newScores[i];

        if (oldIds.indexOf(score.id) === -1) {
            result.push({
                ...score,
                isUpdated: true,
            });
        }
    }

    return result;
};

const unsetAssignmentsScoresUpdated = (oldScores, newScores) => {
    return oldScores.map((score) => {
        let isUpdated = false;

        const newScore = array.findOneById(newScores, score.id);

        if (newScore) {
            isUpdated = false;
        }

        return {
            ...score,
            isUpdated,
        };
    });
};

const updateStudentsAssignmentsScores = (oldStudents, newStudents) => {
    const result = [];

    const newIds = newStudents.map((student) => student.id);
    const oldIds = [];

    for (let i = 0; i < oldStudents.length; i += 1) {
        const student = oldStudents[i];

        if (newIds.indexOf(student.id) !== -1) {
            oldIds.push(student.id);

            const scores = student.quizzesScores || [];

            const newStudent = array.findOneById(newStudents, student.id);
            const newStudentScores = newStudent?.quizzesScores || [];

            const newScores = setAssignmentsScoresUpdated(scores, newStudentScores);

            result.push({
                ...student,
                quizzesScores: newScores,
            });
        }
    }

    for (let i = 0; i < newStudents.length; i += 1) {
        const student = newStudents[i];

        if (oldIds.indexOf(student.id) === -1) {
            result.push({
                ...student,
                isUpdated: true,
            });
        }
    }

    return result;
};

const clearStudentsAssignmentsScoresUpdatedStatus = (oldStudents, newStudents) => {
    return oldStudents.map((student) => {
        const scores = student.quizzesScores || [];

        const newStudent = array.findOneById(newStudents, student.id);
        const newStudentScores = newStudent?.quizzesScores || [];

        const newScores = unsetAssignmentsScoresUpdated(scores, newStudentScores);

        return {
            ...student,
            isUpdated: false,
            quizzesScores: newScores,
        };
    });
};

/* --- */

const setAchievementsUpdated = (oldAchievements, newAchievements) => {
    const result = [];
    const oldTypes = [];

    for (let i = 0; i < oldAchievements.length; i += 1) {
        const oldAchievement = oldAchievements[i];
        const newAchievement = array.findOneByType(newAchievements, oldAchievement.type);

        const isUpdated = oldAchievement?.value !== newAchievement?.value;

        result.push({
            ...oldAchievement,
            ...newAchievement,
            isUpdated,
        });

        if (oldAchievement?.type) {
            oldTypes.push(oldAchievement.type);
        }
    }

    for (let i = 0; i < newAchievements.length; i += 1) {
        const newAchievement = newAchievements[i];
        const type = newAchievement?.type || "";

        if (oldTypes.indexOf(type) === -1) {
            result.push({
                ...newAchievement,
                isUpdated: true,
            });
        }
    }

    return result;
};

const unsetAchievementsUpdated = (oldAchievements, newAchievements) => {
    return oldAchievements.map((oldAchievement) => {
        const newAchievement = array.findOneByType(newAchievements, oldAchievement.type);

        const isUpdated = newAchievement ? false : oldAchievement.isUpdated;

        return {
            ...oldAchievement,
            isUpdated,
        };
    });
};

const updateStudentsAchievements = (oldStudents, newStudents) => {
    const result = [];
    const oldIds = [];

    for (let i = 0; i < oldStudents.length; i += 1) {
        const student = oldStudents[i];

        const achievements = student.achievements || [];

        const newStudent = array.findOneById(newStudents, student.id);
        const newAchievements = newStudent?.achievements || [];

        const val = setAchievementsUpdated(achievements, newAchievements);

        result.push({
            ...student,
            achievements: val,
        });

        oldIds.push(student.id);
    }

    for (let i = 0; i < newStudents.length; i += 1) {
        const newStudent = newStudents[i];

        if (oldIds.indexOf(newStudent.id) === -1) {
            const newStudentAchievements = newStudent?.achievements || [];
            const achievements = setAchievementsUpdated([], newStudentAchievements);

            result.push({
                ...newStudent,
                achievements,
            });
        }
    }

    return result;
};

const clearStudentsAchievementsUpdatedStatus = (oldStudents, newStudents) => {
    return oldStudents.map((student) => {
        const achievements = student.achievements || [];

        const newStudent = array.findOneById(newStudents, student.id);
        const newAchievements = newStudent?.achievements || [];

        const val = unsetAchievementsUpdated(achievements, newAchievements);

        return {
            ...student,
            achievements: val,
        };
    });
};

/* --- */

export default {
    getClassesDefaultGrade,

    updateStudentsScores,
    clearStudentsScoresUpdatedStatus,

    updateStudentsAssignmentsScores,
    clearStudentsAssignmentsScoresUpdatedStatus,

    updateStudentsAchievements,
    clearStudentsAchievementsUpdatedStatus,
};
