// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nUNRwuxhfr7TYyWGTlgL {\n    display: grid;\n    justify-content: center;\n}\n\n.RDdFbrc1p_QjQRlBDjRQ {\n    z-index: var(--z-index-popup-common);\n}\n\n.hRroFzwt6flH1qk7fvN_ {\n    position: relative;\n\n    height: min-content;\n    padding: 4rem;\n\n    color: var(--text-primary-color);\n    background-color: var(--text-primary-bg-color);\n    box-shadow: var(--box-shadow-2);\n\n    border-radius: 1rem;\n}\n\n.HxTOh_nXQebuuuKQkl4B {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n\n.HxTOh_nXQebuuuKQkl4B:before, .HxTOh_nXQebuuuKQkl4B:after {\n    background-color: black;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-text/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;;IAElB,mBAAmB;IACnB,aAAa;;IAEb,gCAAgC;IAChC,8CAA8C;IAC9C,+BAA+B;;IAE/B,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".textPopup {\n    display: grid;\n    justify-content: center;\n}\n\n.overlappedPopup {\n    z-index: var(--z-index-popup-common);\n}\n\n.textContainer {\n    position: relative;\n\n    height: min-content;\n    padding: 4rem;\n\n    color: var(--text-primary-color);\n    background-color: var(--text-primary-bg-color);\n    box-shadow: var(--box-shadow-2);\n\n    border-radius: 1rem;\n}\n\n.close {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n\n.close:before, .close:after {\n    background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textPopup": "nUNRwuxhfr7TYyWGTlgL",
	"overlappedPopup": "RDdFbrc1p_QjQRlBDjRQ",
	"textContainer": "hRroFzwt6flH1qk7fvN_",
	"close": "HxTOh_nXQebuuuKQkl4B"
};
export default ___CSS_LOADER_EXPORT___;
