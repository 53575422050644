import text from "@/base/text/index.js";


const loadStudentAchievements = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.studentAchievements.setAchievementsLoading());

        const res = await api.students.getStudentAchievements({
            session: state.user.session,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.data || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAchievements.setAchievements({
            data,
            error,
        }));
    };
};

const reloadStudentAchievements = (services) => {
    const { actions, api } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.students.getStudentAchievements({
            session: state.user.session,
        });

        let data = [];
        let error = "";

        if (res.ok) {
            data = res.data || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAchievements.setAchievements({
            data,
            error,
        }));
    };
};

const loadOrReloadStudentAchievements = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        if (state.studentAchievements.isLoaded) {
            dispatch(reloadStudentAchievements(services));
            return;
        }

        dispatch(loadStudentAchievements(services));
    };
};


export default {
    loadStudentAchievements,
    reloadStudentAchievements,
    loadOrReloadStudentAchievements,
};
