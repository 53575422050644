import React, { useEffect } from "react";

import classNames from "@/base/lib/class-names.js";

import { groupWordDefinitionsByPos } from "@/base/project/vocabulary.js";

import IconAudioPlay from "@/base/icons/audio-play/index.js";
import IconAudioSound from "@/base/icons/audio-sound/index.js";
import IconAudioStop from "@/base/icons/audio-stop/index.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import IconSoundLoading from "@/base/components/icon-sound-loading/index.js";
import EventsWrapper from "@/base/components/events-wrapper/index.js";

import styles from "./styles.module.css";


const WordCard = (props) => {
    const groupedDefinitions = groupWordDefinitionsByPos(props.definitions);

    useEffect(() => {
        return () => {
            const ds = groupedDefinitions.map(([pos, gDs]) => {
                const text = [pos];
                gDs.forEach((d, i) => {
                    text.push(`${i + 1}. ${d.definition}.`);
                });

                return text.join(". ");
            });

            props.onStopAll([
                props.word.word,
                ...ds,
            ]);
        };
    }, []);

    const onSpeech = (text, isDefinition) => {
        if (!text) {
            return;
        }

        if (!props.audio[text]) {
            props.onLoad(text, props.word.id, isDefinition);
        }
    };

    const renderTitle = () => {
        if (!props.isTitleVisible) {
            return null;
        }

        return (
            <div className={styles.wordTitle}>
                Vocabulary
            </div>
        );
    };

    const renderWordIcon = (text, isDefinition = false) => {
        const { audio } = props;

        if (audio[text] && audio[text].isLoading) {
            return (
                <IconSoundLoading />
            );
        }

        if (audio[text] && audio[text].isPlaying) {
            return (
                <IconClickable
                    className={styles.controlIcon}
                    onClick={() => {
                        props.onStop(text, props.word.id, isDefinition);
                    }}
                >
                    <IconAudioStop
                        className={styles.controlIcon}
                        title="Stop"
                        isSky
                    />
                </IconClickable>
            );
        }

        if (audio[text]
            && !audio[text].isLoading
            && !audio[text].isPlaying) {
            return (
                <IconClickable
                    className={styles.controlIcon}
                    onClick={() => {
                        props.onPlay(text, props.word.id, isDefinition);
                    }}
                >
                    <IconAudioPlay
                        className={styles.controlIcon}
                        title="Play"
                        isSky
                    />
                </IconClickable>
            );
        }

        return (
            <IconClickable
                className={styles.controlIcon}
                onClick={() => {
                    onSpeech(text, isDefinition);
                }}
            >
                <IconAudioSound
                    className={styles.controlIcon}
                    title="Sound"
                    isSky
                />
            </IconClickable>
        );
    };

    const renderDefinitions = () => {
        const ds = groupedDefinitions.map(([pos, gDefinitions], index) => {
            const isLast = index === groupedDefinitions.length - 1;

            const lis = [];
            const text = [pos];

            gDefinitions.forEach((d, i) => {
                text.push(`${i + 1}. ${d.definition}.`);

                const li = (
                    <li className={styles.definitionPosValue}>
                        {d.definition}
                    </li>
                );

                lis.push(li);
            });

            const ulClassName = classNames({
                [styles.definitionPosValues]: true,
                [styles.definitionPosValuesWithBorder]: !isLast,
            });

            return (
                <div
                    key={`word-definitions-${props.word.id}-${pos}`}
                >
                    <div className={styles.definitionPos}>
                        {renderWordIcon(text.join(". "), true)}
                        <div className={styles.definitionPosValue}>
                            {pos}
                        </div>
                    </div>
                    <ul className={ulClassName}>
                        {lis}
                    </ul>
                </div>
            );
        });

        return (
            <div className={styles.definitions}>
                {ds}
            </div>
        );
    };

    if (!props.word) {
        return null;
    }

    const classes = classNames({
        [styles.wordCard]: true,
        [props.className]: props.className,
    });

    return (
        <EventsWrapper className={classes}>
            {renderTitle()}

            <div className={styles.word}>
                <div className={styles.wordSoundIcon}>
                    {renderWordIcon(props.word.word)}
                </div>

                <div className={styles.wordValue}>
                    {props.word.word}
                </div>

                <div className={styles.wordPronunciation}>
                    {props.word.pronunciation}
                </div>
            </div>

            {renderDefinitions()}
        </EventsWrapper>
    );
};

WordCard.defaultProps = {
    word: null,
    definitions: [],
    audio: {},
    className: "",
    onLoad: () => { },
    onPlay: () => { },
    onStop: () => { },
    onStopAll: () => { },
    isTitleVisible: false,
};

export default WordCard;
