// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PutgpZgjOsd50P4omjaC {\n    position: relative;\n\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 50%;\n    background-color: transparent;\n\n    transition: background-color 100ms linear;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.PutgpZgjOsd50P4omjaC:hover {\n    background-color: var(--button-circle-bg-color-hover);\n}\n\n.PutgpZgjOsd50P4omjaC > svg {\n    width: 2.1rem;\n    height: 2.1rem;\n}\n\n.dupVUFW9VULLE7PJQ6Y3 {\n    border-color: var(--color-4);\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-circle-toggler/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,gDAAgD;IAChD,kBAAkB;IAClB,6BAA6B;;IAE7B,yCAAyC;;IAEzC,iBAAiB;;IAEjB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,qDAAqD;AACzD;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".button {\n    position: relative;\n\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 50%;\n    background-color: transparent;\n\n    transition: background-color 100ms linear;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.button:hover {\n    background-color: var(--button-circle-bg-color-hover);\n}\n\n.button > svg {\n    width: 2.1rem;\n    height: 2.1rem;\n}\n\n.buttonChecked {\n    border-color: var(--color-4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "PutgpZgjOsd50P4omjaC",
	"buttonChecked": "dupVUFW9VULLE7PJQ6Y3"
};
export default ___CSS_LOADER_EXPORT___;
