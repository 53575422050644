import * as types from "./types.js";


export const setAchievementsLoading = (payload) => ({
    type: types.SET_ACHIEVEMENTS_LOADING,
    payload,
});

export const setAchievements = (payload) => ({
    type: types.SET_ACHIEVEMENTS,
    payload,
});

export const addStoriesRead = (payload = {}) => ({
    type: types.ADD_STORIES_READ,
    payload,
});

export const addQuestionsAttempted = (payload = {}) => ({
    type: types.ADD_QUESTIONS_ATTEMPTED,
    payload,
});

export const clear = (payload) => ({
    type: types.CLEAR_ACHIEVEMENTS,
    payload,
});
