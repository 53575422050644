import array from "@/base/lib/array.js";
import date from "@/base/lib/date.js";

import Grades from "@/base/project/grades.js";


const getClassCodeById = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].classCode || "";
        }
    }

    return "";
};

const setClassCodeById = (classes, classId, classCode) => {
    return classes.map((cls) => {
        if (cls.id === classId) {
            return {
                ...cls,
                classCode,
            };
        }

        return cls;
    });
};

const setClassEndDateById = (classes, classId, endDate) => {
    return classes.map((cls) => {
        if (cls.id === classId) {
            return {
                ...cls,
                endDate,
            };
        }

        return cls;
    });
};

const getClassGradeById = (classes, classId) => {
    const cls = array.findOneById(classes, classId);

    if (cls) {
        return cls.grade;
    }

    return "";
};

const getClassEndDateById = (classes, classId) => {
    const cls = array.findOneById(classes, classId);

    if (cls) {
        return cls.endDate;
    }

    return "";
};

const getClassMinMaxEndDate = (currentDate, expirationDate) => {
    const min = currentDate
        ? date.newDate(currentDate)
        : new Date();

    const dates = {
        min,
        max: null,
    };

    if (!expirationDate) {
        return dates;
    }

    const diffDays = date.getDiffInDays(min, expirationDate);

    if (diffDays < 365) {
        dates.max = date.addYears(min, 1);
    } else {
        dates.max = date.newDate(expirationDate);
    }

    return dates;
};

const getClassNameById = (classes, classId) => {
    const cls = array.findOneById(classes, classId);
    return cls ? cls.name || "" : "";
};

const getClassTeacherById = (classTeachers, teacherId) => {
    const teacher = array.findOneById(classTeachers, teacherId);
    return teacher || null;
};

const getClassPrimaryTeacher = (classTeachers) => {
    const teacher = array.findByFieldName(classTeachers, "isPrimaryTeacher", true);
    return teacher || null;
};

const getClassById = (classes, classId) => {
    if (classes.length === 0 || !classId || classId === -1) {
        return null;
    }

    const cls = array.findOneById(classes, classId);

    if (!cls) {
        return null;
    }

    return cls;
};

const getClassLimit = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].classLimit;
        }
    }

    return 35;
};

const getClassesMaxGrade = (classes) => {
    const grades = classes.map((cl) => Grades.getValidGrade(cl.grade));

    return Grades.findMaxGrade(grades);
};

const getEmptyClasses = (classes) => {
    const emptyClasses = [];

    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].studentsCount === 0) {
            emptyClasses.push(classes[i]);
        }
    }

    return emptyClasses;
};

const getPrimaryClasses = (classes) => {
    const primaryClasses = [];

    for (let i = 0; i < classes.length; i += 1) {
        const cl = classes[i];

        if (cl.isPrimaryTeacher) {
            primaryClasses.push(cl);
        }
    }

    return primaryClasses;
};

const getStudentsCount = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].studentsCount;
        }
    }

    return 0;
};

/* --- */

const isClassesLimitExceeded = (data) => {
    const maxStudents = data?.maxStudents || 0;
    const totalStudents = data?.totalStudents || 0;

    return totalStudents > maxStudents;
};

/* --- */

const hasBounceNotification = (classes) => {
    const cls = classes || [];

    for (let i = 0; i < cls.length; i += 1) {
        const c = cls[i];

        if (c.isBounceNotificationsExists) {
            return true;
        }
    }

    return false;
};

/* --- */

const hasNewProgressReport = (classes) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].hasNewProgressReport) {
            return true;
        }
    }

    return false;
};

const hasClassNewProgressReport = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].hasNewProgressReport || false;
        }
    }

    return false;
};

const setClassNewProgressReport = (classes, classId, hasNewReport) => {
    return classes.map((cls) => {
        if (cls.id === classId) {
            return {
                ...cls,
                hasNewProgressReport: hasNewReport,
            };
        }

        return cls;
    });
};

/* --- */

const hasSelectedClass = (classId) => {
    if (!classId || classId === -1) {
        return false;
    }

    return true;
};

const hasSameClassName = (classes, className) => {
    if (!classes || classes.length === 0) {
        return false;
    }

    for (let i = 0; i < classes.length; i += 1) {
        const dName = classes[i].name || "";

        if (dName === className) {
            return true;
        }
    }

    return false;
};

/* ------ */

export default {
    getClassCodeById,
    setClassCodeById,
    setClassEndDateById,

    getClassGradeById,
    getClassEndDateById,
    getClassMinMaxEndDate,

    getClassNameById,
    getClassTeacherById,
    getClassPrimaryTeacher,
    getClassById,
    getClassLimit,
    getClassesMaxGrade,

    getPrimaryClasses,

    getEmptyClasses,

    isClassesLimitExceeded,

    getStudentsCount,

    hasBounceNotification,

    hasNewProgressReport,
    hasClassNewProgressReport,
    setClassNewProgressReport,

    hasSameClassName,

    hasSelectedClass,
};
