import User from "@/base/project/user.js";

import { getTeacherCallbacks } from "./teacher.js";
import { getDistrictAdminCallbacks } from "./district-admin.js";
import { getStudentCallbacks } from "./student.js";


const getCallbacks = (store) => {
    if (!store.getState) {
        return {};
    }

    const user = store.getState()?.user?.user || {};

    if (User.hasRoleDistrictAdmin(user)) {
        return getDistrictAdminCallbacks(store);
    }

    if (User.hasRoleTeacher(user)) {
        return getTeacherCallbacks(store);
    }

    if (User.hasRoleStudent(user)) {
        return getStudentCallbacks(store);
    }

    return {};
};

/* --- */

export default {
    getCallbacks,
};
