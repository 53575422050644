const GRADE = {
    G5: "G5",
    G6: "G6",
    G7: "G7",
    G8: "G8",
    G9: "G9",
    G10: "G10",
    G11: "G11",
    G12: "G12",
};

const GRADES = [
    {
        range: "5-6",
        value: "G5-G6",
        name: "Level 5-6",
    },
    {
        range: "7-8",
        value: "G7-G8",
        name: "Level 7-8",
    },
    {
        range: "9-10",
        value: "G9-G10",
        name: "Level 9-10",
    },
    {
        range: "11-12",
        value: "G11-G12",
        name: "Level 11+",
    },
];

const GRADES_RANGES = {
    G5G6: "G5-G6",
    G7G8: "G7-G8",
    G9G10: "G9-G10",
    G11G12: "G11-G12",
};

const GRADE_GT = {
    range: "GT",
    value: "GT",
    name: "GT",
    // NOTE: dropdown support
    label: "GT",
};

const GRADES_ALL = {
    range: "all",
    value: "all",
    name: "All Reading Levels",
    label2: "All Levels Selected",
};

/* --- */

const removeGradeGT = (grades = []) => {
    return grades.filter((g) => g !== GRADE_GT.range);
};

/* --- */

const isGradesG5G6 = (value) => {
    if (value.indexOf("5") !== -1 || value.indexOf("6") !== -1) {
        return true;
    }

    return false;
};

const isGradesG7G8 = (value) => {
    if (value.indexOf("7") !== -1 || value.indexOf("8") !== -1) {
        return true;
    }

    return false;
};

const isGradesG9G10 = (value) => {
    if (value.indexOf("9") !== -1 || value.indexOf("10") !== -1) {
        return true;
    }

    return false;
};

const isGradesG11G12 = (value) => {
    if (value.indexOf("11") !== -1 || value.indexOf("12") !== -1) {
        return true;
    }

    return false;
};

const isGradesGT = (grades) => {
    return (grades || []).indexOf(GRADE_GT.value) !== -1;
};

/* --- */

const getGradesFromRange = (range) => {
    if (GRADES_RANGES.G5G6 === range) {
        return [
            GRADE.G5,
            GRADE.G6,
        ];
    }

    if (GRADES_RANGES.G7G8 === range) {
        return [
            GRADE.G7,
            GRADE.G8,
        ];
    }

    if (GRADES_RANGES.G9G10 === range) {
        return [
            GRADE.G9,
            GRADE.G10,
        ];
    }

    if (GRADES_RANGES.G11G12 === range) {
        return [
            GRADE.G11,
            GRADE.G12,
        ];
    }

    return [];
};

const getOneGradeRangeByGrades = (grades) => {
    for (let i = 0; i < grades.length; i += 1) {
        const grade = grades[i];

        if (isGradesG5G6(grade)) {
            return GRADES_RANGES.G5G6;
        }

        if (isGradesG7G8(grade)) {
            return GRADES_RANGES.G7G8;
        }

        if (isGradesG9G10(grade)) {
            return GRADES_RANGES.G9G10;
        }

        if (isGradesG11G12(grade)) {
            return GRADES_RANGES.G11G12;
        }
    }

    return "";
};

/* --- */

const isRangeInGrades = (range, grades) => {
    const gradesFromRange = range.split("-");

    for (let i = 0; i < grades.length; i += 1) {
        const grade = grades[i];

        if (gradesFromRange.indexOf(grade) !== -1) {
            return true;
        }
    }

    return false;
};

const getGradesRangeByGrade = (grade) => {
    if (grade === "G5" || grade === "G6") {
        return GRADES_RANGES.G5G6;
    }

    if (grade === "G7" || grade === "G8") {
        return GRADES_RANGES.G7G8;
    }

    if (grade === "G9" || grade === "G10") {
        return GRADES_RANGES.G9G10;
    }

    if (grade === "G11" || grade === "G12") {
        return GRADES_RANGES.G11G12;
    }

    if (grade === "GT") {
        return GRADE_GT.range;
    }

    return "";
};

const getGradeRange = (grades = []) => {
    if (!Array.isArray(grades)) {
        return grades;
    }

    if (grades.length === 1) {
        const grade = grades[0];

        if (grade === "G5" || grade === "G6") {
            return "5-6";
        }

        if (grade === "G7" || grade === "G8") {
            return "7-8";
        }

        if (grade === "G9" || grade === "G10") {
            return "9-10";
        }

        if (grade === "G11" || grade === "G12") {
            return "11-12";
        }
    }

    return grades.join(", ");
};

const getHigherRange = (range) => {
    if (GRADES_RANGES.G5G6 === range) {
        return GRADES_RANGES.G7G8;
    }

    if (GRADES_RANGES.G7G8 === range) {
        return GRADES_RANGES.G9G10;
    }

    if (GRADES_RANGES.G9G10 === range) {
        return GRADES_RANGES.G11G12;
    }

    if (GRADES_RANGES.G11G12 === range) {
        return GRADES_RANGES.G11G12;
    }

    return "";
};

const getLowerRange = (range) => {
    if (GRADES_RANGES.G5G6 === range) {
        return GRADES_RANGES.G5G6;
    }

    if (GRADES_RANGES.G7G8 === range) {
        return GRADES_RANGES.G5G6;
    }

    if (GRADES_RANGES.G9G10 === range) {
        return GRADES_RANGES.G7G8;
    }

    if (GRADES_RANGES.G11G12 === range) {
        return GRADES_RANGES.G9G10;
    }

    return "";
};

/* --- */

const getGradesRanges = () => {
    return GRADES.map((val) => val.range);
};

const getGradesAllRanges = () => {
    return {
        ...GRADES_RANGES,
        GT: GRADE_GT.range,
    };
};

const getGradesOptions = () => {
    return GRADES.map((val) => ({
        value: val.value,
        name: val.name,
        range: val.range,
        // NOTE: dropdown support
        label: val.name,
    }));
};

const getGradesOptionsByGrades = (grades = []) => {
    const allRanges = [];

    for (let i = 0; i < grades.length; i += 1) {
        const grade = grades[i];
        const range = getGradesRangeByGrade(grade);

        if (allRanges.indexOf(range) === -1) {
            allRanges.push(range);
        }
    }

    const res = [];

    for (let i = 0; i < GRADES.length; i += 1) {
        const grade = GRADES[i];

        if (allRanges.indexOf(grade) === -1) {
            res.push({
                value: grade.value,
                name: grade.name,
                range: grade.range,
                // NOTE: dropdown support
                label: grade.name,
            });
        }
    }

    return res;
};

const getGradesOptionsByGradesWithGT = (grades = []) => {
    const res = getGradesOptionsByGrades(grades);

    if (grades.indexOf("GT") !== -1) {
        res.push({
            ...GRADE_GT,
        });
    }

    return res;
};

const getGradesAllOptions = () => {
    const gradesAll = [
        ...GRADES,
    ].concat(GRADES_ALL);

    return gradesAll.map((val) => ({
        value: val.value,
        name: val.name,
        range: val.range,
        label: val.name,
        label2: val.label2 || "",
    }));
};

const getGradesOpt = () => {
    return GRADES.map((val) => ({
        value: val.value,
        name: val.range,
    }));
};

const filterGradesDuplicate = (grades) => {
    const ret = [];

    const allGradesChecks = [
        isGradesG5G6,
        isGradesG7G8,
        isGradesG9G10,
        isGradesG11G12,
    ];

    for (let i = 0; i < allGradesChecks.length; i += 1) {
        const isSameGrade = allGradesChecks[i];

        for (let j = 0; j < grades.length; j += 1) {
            const grade = grades[j];

            if (isSameGrade(grade)) {
                ret.push(grade);
                break;
            }
        }
    }

    return ret;
};

const getGradesOptionsWithGT = () => {
    const grades = getGradesOptions();
    return grades.concat(GRADE_GT);
};

const getMaxGrades = () => {
    return ["G11", "G12"];
};

const getMaxGradeValue = () => {
    return GRADES_RANGES.G11G12;
};

const getGradeGroup = (grades = []) => {
    if (typeof grades !== "object") {
        return grades;
    }

    let firstGrade = "G5";

    if (grades.length > 0) {
        firstGrade = grades[0] || "G5";
    }

    if (firstGrade === "G5" || firstGrade === "G6") {
        return "5-6";
    }

    if (firstGrade === "G7" || firstGrade === "G8") {
        return "7-8";
    }

    if (firstGrade === "G9" || firstGrade === "G10") {
        return "9-10";
    }

    if (firstGrade === "G11" || firstGrade === "G12") {
        return "11+";
    }

    if (firstGrade === "GT") {
        return "GT";
    }

    return "5-6";
};

const getGradeGroupByString = (grade) => {
    if (grade.indexOf("G5") !== -1 || grade.indexOf("G6") !== -1) {
        return "5-6";
    }

    if (grade.indexOf("G7") !== -1 || grade.indexOf("G8") !== -1) {
        return "7-8";
    }

    if (grade.indexOf("G9") !== -1 || grade.indexOf("G10") !== -1) {
        return "9-10";
    }

    if (grade.indexOf("G11") !== -1 || grade.indexOf("G12") !== -1) {
        return "11+";
    }

    if (grade.indexOf("GT") !== -1) {
        return "GT";
    }

    return "5-6";
};

const getValidGrade = (grade = "", defaultGrade = GRADES_RANGES.G7G8) => {
    if (grade.indexOf("5") !== -1 || grade.indexOf("6") !== -1) {
        return GRADES_RANGES.G5G6;
    }

    if (grade.indexOf("7") !== -1 || grade.indexOf("8") !== -1) {
        return GRADES_RANGES.G7G8;
    }

    if (grade.indexOf("9") !== -1 || grade.indexOf("10") !== -1) {
        return GRADES_RANGES.G9G10;
    }

    if (grade.indexOf("11") !== -1 || grade.indexOf("12") !== -1) {
        return GRADES_RANGES.G11G12;
    }

    if (grade.indexOf("GT") !== -1) {
        return GRADE_GT.range;
    }

    return defaultGrade;
};

const getValidGrades = (grades = []) => {
    const filteredGrades = removeGradeGT(grades);

    const grade = filteredGrades[0] || null;

    if (!grade) {
        return getGradesFromRange(GRADES_RANGES.G11G12);
    }

    const validGrade = getValidGrade(grade);

    return validGrade.split("-");
};

const getGradeNameByValue = (value = "") => {
    let gradeName = "";

    for (let i = 0; i < GRADES.length; i += 1) {
        if (GRADES[i].value === value) {
            gradeName = GRADES[i].name;
            break;
        }
    }

    return gradeName;
};

const getGradeRangeByGrade = (grade = "") => {
    if (isGradesG5G6(grade)) {
        return "5-6";
    }

    if (isGradesG7G8(grade)) {
        return "7-8";
    }

    if (isGradesG9G10(grade)) {
        return "9-10";
    }

    if (isGradesG11G12(grade)) {
        return "11+";
    }

    if (isGradesGT(grade)) {
        return "GT";
    }

    return "";
};

const convertGradesToString = (grades = []) => {
    if (!Array.isArray(grades) || grades.length < 2) {
        return getMaxGradeValue();
    }

    return grades.join("-");
};

const convertGradesToQueryString = (grades) => {
    if (typeof grades === "string") {
        return grades.replace("-", ",");
    }

    return "all";
};

const findMaxGrade = (gradesArr) => {
    const gradesReversed = [...GRADES].reverse();

    for (let i = 0; i < gradesReversed.length; i += 1) {
        if (gradesArr.indexOf(gradesReversed[i].value) !== -1) {
            return gradesReversed[i].value;
        }
    }

    return "";
};

export default {
    removeGradeGT,

    isGradesG5G6,
    isGradesG7G8,
    isGradesG9G10,
    isGradesG11G12,
    isGradesGT,

    getGradesFromRange,
    getOneGradeRangeByGrades,

    isRangeInGrades,

    getGradesRanges,
    getGradesAllRanges,
    getGradesOptions,
    getGradesOptionsByGrades,
    getGradesOptionsByGradesWithGT,
    getGradesOpt,
    getGradesAllOptions,
    getGradesOptionsWithGT,
    getMaxGrades,
    getMaxGradeValue,
    getGradesRangeByGrade,
    getGradeRange,
    getHigherRange,
    getLowerRange,

    filterGradesDuplicate,

    getGradeGroup,
    getGradeGroupByString,

    getValidGrade,
    getValidGrades,
    getGradeNameByValue,
    getGradeRangeByGrade,

    convertGradesToString,
    convertGradesToQueryString,

    findMaxGrade,
};
