export const SET_TEACHER_CLASSES_LOADING = "SET_TEACHER_CLASSES_LOADING";
export const SET_TEACHER_CLASSES = "SET_TEACHER_CLASSES";

export const SET_TEACHER_CLASS_PROGRESS_REPORT = "SET_TEACHER_CLASS_PROGRESS_REPORT";

export const SET_TEACHER_CLASS_STUDENTS = "SET_TEACHER_CLASS_STUDENTS";
export const SET_LOADING_TEACHER_CLASS_STUDENTS = "SET_LOADING_TEACHER_CLASS_STUDENTS";
export const SET_TEACHER_CLASS_CODE = "SET_TEACHER_CLASS_CODE";
export const SET_TEACHER_CLASS_END_DATE = "SET_TEACHER_CLASS_END_DATE";

export const SET_TEACHER_CLASSES_DETAILS_BY_ID_LOADING = "SET_TEACHER_CLASSES_DETAILS_BY_ID_LOADING";
export const SET_TEACHER_CLASSES_DETAILS_BY_ID = "SET_TEACHER_CLASSES_DETAILS_BY_ID";

export const SET_TEACHER_CLASSES_TEACHERS_BY_ID_LOADING = "SET_TEACHER_CLASSES_TEACHERS_BY_ID_LOADING";
export const SET_TEACHER_CLASSES_TEACHERS_BY_ID = "SET_TEACHER_CLASSES_TEACHERS_BY_ID";
export const SET_TEACHER_CLASSES_PRIMARY_TEACHER = "SET_TEACHER_CLASSES_PRIMARY_TEACHER";

export const SET_TEACHER_SELECTED_CLASS = "SET_TEACHER_SELECTED_CLASS";
export const SET_TEACHER_STATS_RANGE = "SET_TEACHER_STATS_RANGE";

export const SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS = "SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS";
export const DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD = "DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD";

export const SET_TEACHER_STUDENTS_WITH_PENDING_STATUS = "SET_TEACHER_STUDENTS_WITH_PENDING_STATUS";
export const SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING = "SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING";
export const CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS = "CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS";
export const DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID = "DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID";

export const CLEAR_CLASSES_SCORES = "CLEAR_CLASSES_SCORES";

export const SET_TEACHER_CLASS_RATE_DETAILS_LOADING = "SET_TEACHER_CLASS_RATE_DETAILS_LOADING";
export const SET_TEACHER_CLASS_RATE_DETAILS = "SET_TEACHER_CLASS_RATE_DETAILS";

export const SET_TEACHER_CLASS_RATE_LOADING = "SET_TEACHER_CLASS_RATE_LOADING";
export const SET_TEACHER_CLASS_RATE = "SET_TEACHER_CLASS_RATE";

export const CLEAR_STUDENTS_SCORES_BY_RANGE = "CLEAR_STUDENTS_SCORES_BY_RANGE";
export const SET_TEACHER_CLASS_SCORES_BY_RANGE_LOADING = "SET_TEACHER_CLASS_SCORES_BY_RANGE_LOADING";
export const SET_TEACHER_CLASS_SCORES_BY_RANGE = "SET_TEACHER_CLASS_SCORES_BY_RANGE";
export const UPDATE_TEACHER_CLASS_SCORES_BY_DAILY_JUICE = "UPDATE_TEACHER_CLASS_SCORES_BY_DAILY_JUICE";
export const CLEAR_UPDATED_STATUS_TEACHER_CLASS_SCORES_BY_DAILY_JUICE = "CLEAR_UPDATED_STATUS_TEACHER_CLASS_SCORES_BY_DAILY_JUICE";

export const SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES_LOADING = "SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES_LOADING";
export const SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES = "SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES";
export const CLEAR_TEACHER_CLASSES_SCORES_BY_DAILY_JUICES = "CLEAR_TEACHER_CLASSES_SCORES_BY_DAILY_JUICES";

export const SET_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS_LOADING = "SET_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS_LOADING";
export const SET_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS = "SET_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS";
export const UPDATE_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS = "UPDATE_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS";
export const CLEAR_TEACHER_CLASSES_SCORES_BY_ASSIGNMENTS = "CLEAR_TEACHER_CLASSES_SCORES_BY_ASSIGNMENTS";
export const CLEAR_UPDATED_STATUS_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS = "CLEAR_UPDATED_STATUS_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS";

export const SET_TEACHER_CLASSES_ANNOUNCEMENTS = "SET_TEACHER_CLASSES_ANNOUNCEMENTS";
export const SET_TEACHER_CLASSES_ANNOUNCEMENTS_LOADING = "SET_TEACHER_CLASSES_ANNOUNCEMENTS_LOADING";
export const SET_TEACHER_CLASSES_ANNOUNCEMENT_BY_ID_SAVING = "SET_TEACHER_CLASSES_ANNOUNCEMENT_BY_ID_SAVING";
export const SET_TEACHER_CLASSES_ANNOUNCEMENT_CONTENT_BY_ID = "SET_TEACHER_CLASSES_ANNOUNCEMENT_CONTENT_BY_ID";
export const SET_TEACHER_CLASSES_ANNOUNCEMENTS_BY_ID = "SET_TEACHER_CLASSES_ANNOUNCEMENTS_BY_ID";
export const SET_TEACHER_CLASSES_ANNOUNCEMENTS_BY_ID_LOADING = "SET_TEACHER_CLASSES_ANNOUNCEMENTS_BY_ID_LOADING";

export const SET_TEACHER_CLASSES_SPONSORS = "SET_TEACHER_CLASSES_SPONSORS";
export const SET_TEACHER_CLASSES_SPONSORS_LOADING = "SET_TEACHER_CLASSES_SPONSORS_LOADING";
export const SET_TEACHER_CLASSES_SPONSORS_BY_ID_SAVING = "SET_TEACHER_CLASSES_SPONSORS_BY_ID_SAVING";
export const SET_TEACHER_CLASSES_SPONSORS_CONTENT_BY_ID = "SET_TEACHER_CLASSES_SPONSORS_CONTENT_BY_ID";
export const SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID_CLEAR = "SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID_CLEAR";
export const SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID_LOADING = "SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID_LOADING";
export const SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID = "SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID";
export const SET_TEACHER_CLASSES_SPONSORS_BY_ID_LOADING = "SET_TEACHER_CLASSES_SPONSORS_BY_ID_LOADING";
export const SET_TEACHER_CLASSES_SPONSORS_BY_ID = "SET_TEACHER_CLASSES_SPONSORS_BY_ID";

export const CLEAR_TEACHER_CLASSES_CATEGORIES = "CLEAR_TEACHER_CLASSES_CATEGORIES";
export const SET_TEACHER_CLASSES_CATEGORIES_BY_RANGE_LOADING = "SET_TEACHER_CLASSES_CATEGORIES_BY_RANGE_LOADING";
export const SET_TEACHER_CLASSES_CATEGORIES_BY_RANGE = "SET_TEACHER_CLASSES_CATEGORIES_BY_RANGE";

export const SET_TEACHER_CLASS_AVERAGE_SCORES_LOADING = "SET_TEACHER_CLASS_AVERAGE_SCORES_LOADING";
export const SET_TEACHER_CLASS_AVERAGE_SCORES = "SET_TEACHER_CLASS_AVERAGE_SCORES";

export const SET_TEACHER_CLASSES_AVERAGE_SCORES_BY_STANDARDS_LOADING = "SET_TEACHER_CLASSES_AVERAGE_SCORES_BY_STANDARDS_LOADING";
export const SET_TEACHER_CLASSES_AVERAGE_SCORES_BY_STANDARDS = "SET_TEACHER_CLASSES_AVERAGE_SCORES_BY_STANDARDS";

export const SET_TEACHER_CLASSES_PERFORMANCE_BY_RANGE_LOADING = "SET_TEACHER_CLASSES_PERFORMANCE_BY_RANGE_LOADING";
export const SET_TEACHER_CLASSES_PERFORMANCE_BY_RANGE = "SET_TEACHER_CLASSES_PERFORMANCE_BY_RANGE";
export const UPDATE_TEACHER_CLASSES_PERFORMANCE_BY_RANGE = "UPDATE_TEACHER_CLASSES_PERFORMANCE_BY_RANGE";
export const CLEAR_UPDATED_STATUS_TEACHER_CLASSES_PERFORMANCE_BY_RANGE = "CLEAR_UPDATED_STATUSES_TEACHER_CLASSES_PERFORMANCE_BY_RANGE";
export const CLEAR_TEACHER_CLASSES_PERFORMANCE = "CLEAR_TEACHER_CLASSES_PERFORMANCE";

export const SET_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS_LOADING = "SET_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS_LOADING";
export const SET_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS = "SET_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS";
export const CLEAR_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS = "CLEAR_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS";

export const SET_TEACHER_CLASSES_STUDENTS_LOADING = "SET_TEACHER_CLASSES_STUDENTS_LOADING";
export const SET_TEACHER_CLASSES_STUDENTS = "SET_TEACHER_CLASSES_STUDENTS";

export const SET_TEACHER_DEROSTERED_CLASSES_LOADING = "SET_TEACHER_DEROSTERED_CLASSES_LOADING";
export const SET_TEACHER_DEROSTERED_CLASSES = "SET_TEACHER_DEROSTERED_CLASSES";
