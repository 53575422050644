import React, { useEffect, useRef } from "react";

import classNames from "@/base/lib/class-names.js";

import ButtonCircle from "@/base/components/button-circle/index.js";

import Option from "./option.js";

import styles from "./styles.module.css";


const MenuWithButton = (props) => {
    const menuRef = useRef(null);

    /* ------ */

    useEffect(() => {
        const globalClose = (evt) => {
            if (!props.isVisibleMenu) {
                return;
            }

            if (menuRef?.current?.contains
                && menuRef.current.contains(evt.target)) {
                return;
            }

            props.onCloseMenu();
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
            document.addEventListener("scroll", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
                document.removeEventListener("scroll", globalClose, false);
            }
        };
    }, [props.isVisibleMenu]);

    /* ------ */

    const renderMenu = () => {
        if (!props.isVisibleMenu) {
            return null;
        }

        const menuClassName = classNames({
            [styles.menu]: true,
            [styles.menuDown]: props.isMenuOpensDown,
            [styles.menuRight]: props.isMenuOpensRight,
            [styles.menuFullWidth]: props.isFullWidth,
        });

        return (
            <div className={menuClassName}>
                {props.children}
            </div>
        );
    };

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerFullWidth]: props.isFullWidth,
    });

    return (
        <div
            className={containerClassName}
            ref={menuRef}
            data-comment={props.dataComment}
        >
            <ButtonCircle
                icon={props.icon}
                onClick={props.onClick}
            />
            {renderMenu()}
        </div>
    );
};

MenuWithButton.defaultProps = {
    dataComment: "",
    icon: null,

    children: null,

    isFullWidth: false,
    isVisibleMenu: false,
    isMenuOpensDown: true,
    isMenuOpensRight: false,

    onClick: () => { },
    onCloseMenu: () => { },
};

export const MenuOption = Option;
export default MenuWithButton;
