import React from "react";

import classNames from "@/base/lib/class-names.js";

import DivButton from "@/base/components/div-button/index.js";

import styles from "./styles.module.css";


const ButtonCircleToggler = (props) => {
    const onClick = () => {
        props.onClick(!props.isChecked);
    };

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonChecked]: props.isChecked,
    });

    if (!props.icon) {
        return null;
    }

    return (
        <DivButton
            className={buttonClassName}
            onClick={onClick}
        >
            <props.icon
                isOrange={props.isChecked}
            />
        </DivButton>
    );
};

ButtonCircleToggler.defaultProps = {
    icon: null,
    onClick: () => { },
    isChecked: false,
};

export default ButtonCircleToggler;
